<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程评价管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                clearable
                placeholder="请输入姓名"
                size="small"
              ></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                clearable
                placeholder="请输入手机号"
                size="small"
              ></el-input>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                clearable
                placeholder="请输入课程名称"
                size="small"
              ></el-input>
            </div>
          </div>
          <div class="searchbox">
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>

            <div title="评价内容" class="searchboxItem ci-full">
              <span class="itemLabel">评价内容:</span>
              <el-input
                v-model="commentContent"
                clearable
                placeholder="请输入评价内容"
                size="small"
              ></el-input>
            </div>
            <div title="课程评分" class="searchboxItem ci-full">
              <span class="itemLabel">课程评分:</span>
              <el-input
                v-model="commentAverageStart"
                @keyup.native="commentAverageStart = zF.oninput2(commentAverageStart, 1)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="commentAverageEnd"
                @keyup.native="commentAverageEnd  = zF.oninput2(commentAverageEnd, 1)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="exportEva()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                fixed
              />
              <el-table-column
                label="身份证"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                min-width="150"
              />
              <el-table-column
                label="电话"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                label="综合评分"
                align="left"
                show-overflow-tooltip
                prop="actualCompName"
                min-width="130px"
              >
                <template slot-scope="scope">
                  <el-rate
                    v-model="scope.row.commentAverage"
                    disabled
                    show-score
                    text-color="#ff9900"
                    score-template="{value}"
                  >
                  </el-rate>
                </template>
              </el-table-column>
              <el-table-column
                label="评价内容"
                align="left"
                show-overflow-tooltip
                prop="commentContent"
                min-width="220px"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200px"
              >
              </el-table-column>
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="180px"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
                min-width="180px"
              />
              <el-table-column
                label="评价时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="editModel(scope.row)"
                    >评价详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="评价详情"
      :visible.sync="DialogVisible"
      width="30%"
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div>
        <el-form :model="ruleForm" ref="ruleForm" class="Score-ruleForm">
          <el-form-item label="课程设计水平:" prop="commentCompany">
            <el-rate
              v-model="ruleForm.commentCompany"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </el-form-item>
          <el-form-item label="画面质量评价:" prop="commentScore">
            <el-rate
              v-model="ruleForm.commentScore"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </el-form-item>
          <el-form-item label="授课讲师水平:" prop="commentTeacher">
            <el-rate
              v-model="ruleForm.commentTeacher"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </el-form-item>
          <el-form-item label="评价内容:" prop="commentTeacher">
            <span>{{ ruleForm.commentContent }}</span>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "", //姓名
      mobile: "", //手机号
      courseName: "", //课程名称
      projectName: "", //班级名称
      commentContent: "", //评价内容
      commentAverageStart: "",
      commentAverageEnd: "",
      DialogVisible: false, // 评论详情的显示与隐藏
      ruleForm: {}, // 评论详情 - 字段
      taskId: [], // 导出用的id数据
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.commentContent) {
        params.commentContent = this.commentContent;
      }
      if ((this.commentAverageStart && !this.commentAverageEnd) || (!this.commentAverageStart && this.commentAverageEnd)) {
        this.$message.warning("请补全课程评分区间");
        return false;
      } 
      if (this.commentAverageStart && this.commentAverageEnd) {
        if (this.commentAverageStart > this.commentAverageEnd) {
          this.$message.warning("起始分数不能大于截止分数");
          return false;
        } else if (this.commentAverageStart > 5 || this.commentAverageEnd > 5) {
          this.$message.warning("课程评分不可大于5");
          return false;
        } else {
          params.commentAverageStart = this.commentAverageStart;
          params.commentAverageEnd = this.commentAverageEnd;
        }
      }
      this.doFetch({
        url: "/edu/course/comment/pageList",
        params,
        pageNum,
      });
    },
    //评价详情
    editModel(row) {
      this.DialogVisible = true;
      this.ruleForm = {
        ...row,
      };
    },
    // 导出 - 按钮
    exportEva() {
      if (this.projectName === "" && this.courseName === "") {
        this.$message.warning("导出按照班级或者课程导出下载");
        return;
      }
      let params = {};
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.commentContent) {
        params.commentContent = this.commentContent;
      }
       if ((this.commentAverageStart && !this.commentAverageEnd) || (!this.commentAverageStart && this.commentAverageEnd)) {
        this.$message.warning("请补全课程评分区间");
        return false;
      } 
      if (this.commentAverageStart && this.commentAverageEnd) {
        if (this.commentAverageStart > this.commentAverageEnd) {
          this.$message.warning("起始分数不能大于截止分数");
          return false;
        } else if (this.commentAverageStart > 5 || this.commentAverageEnd > 5) {
          this.$message.warning("课程评分不可大于5");
          return false;
        } else {
          params.commentAverageStart = this.commentAverageStart;
          params.commentAverageEnd = this.commentAverageEnd;
        }
      }
      this.$post("/edu/course/comment/export", params)
        .then((ret) => {
          this.taskId.push(ret.data.taskId);
          this.getExport();
        })
        .catch((err) => {
          return;
        });
    },
    // 导出 - 方法
    getExport() {
      this.$post("/sys/download/queryDownloadState", {
        taskIds: this.taskId,
      }).then((res) => {
        if (res.status == "0") {
          for (let item of res.data) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.Score-ruleForm {
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>
